import { track } from "@wise-emotions/gtm-tracking";
import React, { ReactNode, useEffect } from "react";

interface Props {
  children: ReactNode;
  path: string;
  page_url: string;
}

const WrapPage = ({ children, path, page_url }: Props) => {
  useEffect(() => {
    track({ event: "virtual_page_view", page_url });
  }, [path, page_url]);
  return children;
};

export default WrapPage;
