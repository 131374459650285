/*
  Supported browsers for Sentry
  If an error is reported on a browser that is not supported, it will be ignored
*/
export default {
  chrome: ">=100",
  firefox: ">=100",
  opera: ">=80",
  safari: ">=15",
  edge: ">=100",
};
