import "./src/styles/main.scss";
import * as Sentry from "@sentry/react";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import { SiteProvider } from "./src/context/SiteProvider";
import Bowser from "bowser";
import React from "react";
import WrapPage from "./src/context/WrapPage";
import supportedBrowsers from "./supported-browsers";

// NOTE: remove comment for enabled performance in SENTRY
// end sentry

if (process.env.GATSBY_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    // eslint-disable-next-line no-undef
    release: `web-telepass-tpay-public@v${__VERSION__}`,
    environment: process.env.GATSBY_ENV,

    // NOTE: remove comment for enabled performance in SENTRY
    // integrations: [
    //   new Sentry.BrowserTracing({
    //     // NOTE: not tracking a page during navigation but only pageload
    //     startTransactionOnLocationChange: false,
    //   }),
    // ],
    // tracesSampler: (samplingContext) => {
    //   // NOTE: performance
    //   if (samplingContext.location.pathname === "/it/privati/") {
    //     return 0.03;
    //   }
    //   return 0.2;
    // },
    sampleRate: 1,
    tracesSampleRate: false,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      /TypeError/i,
      // https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1352958925
      "https://reactjs.org/docs/error-decoder.html?invariant=418", // This Suspense boundary received an update before it finished hydrating.....
      "https://reactjs.org/docs/error-decoder.html?invariant=421", // This Suspense boundary received an update before it finished hydrating.....
      "https://reactjs.org/docs/error-decoder.html?invariant=422", // There was an error while hydrating this Suspense boundary. Switched to client rendering.
      "https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
      "https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...],
    ],
    allowUrls: [/telepass.com/, /public.netlify.app/],
    beforeSend: (event) => {
      const browser = Bowser.getParser(window.navigator.userAgent);
      if (!browser.satisfies(supportedBrowsers)) {
        return null;
      }
      return event;
    },
  });
}

export const wrapRootElement = ({ element }) => {
  return (
    <ContentfulLivePreviewProvider
      locale="it"
      // eslint-disable-next-line react/jsx-boolean-value
      // enableInspectorMode={true} // Optional: allows you to toggle inspector mode which is on by default.
      // enableLiveUpdates // Optional: allows you to toggle live updates which are on by default.
      targetOrigin={[
        "https://app.contentful.com",
        "https://telepass-public-live-preview.netlify.app",
      ]}
      debugMode
    >
      <SiteProvider>{element}</SiteProvider>
    </ContentfulLivePreviewProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <WrapPage path={props.path} page_url={props.location.href}>
      {element}
    </WrapPage>
  );
};
